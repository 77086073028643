<template>
  <div class="verificationDetail">
    <header class="show-card_wrap">
      <div class="order-info">
        <span class="time">{{ orderDetail.updateTime }}</span>
        <span
          class="status"
          :style="{
            color: orderDetail.status === 1 ? '#fe6f16' : '#007EFF',
          }"
          >{{ returnStatus(orderDetail.status) }}</span
        >
      </div>
      <div class="order-detail">
        <div class="left-pic">
          <img :src="orderDetail.skuImageUrl" alt="" />
        </div>
        <div class="right-content">
          <div class="content-title">{{ orderDetail.changeTitle }}</div>
          <div class="content-integral">
            <span>{{ orderDetail.pointsPrice }} </span>
            甜蜜值
          </div>
        </div>
      </div>
    </header>
    <div class="main">
      <div class="title">订单信息</div>
      <div class="item">
        <div class="item-l">支付甜蜜值</div>
        <div class="item-r">
          <div>{{ orderDetail.pointsChangeNum }}</div>
        </div>
      </div>
      <div class="item">
        <div class="item-l">兑换数量</div>
        <div class="item-r">{{ orderDetail.exchangeGoodsNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换时间</div>
        <div class="item-r">
          <div>{{ orderDetail.insertTime }}</div>
        </div>
      </div>
      <!-- <div class="item">
        <div class="item-l">核销期限</div>
        <div class="item-r">
          <div>{{ orderDetail.pointsPrice }}</div>
        </div>
      </div> -->
      <div class="item">
        <div class="item-l">订单号</div>
        <div class="item-r">
          <div>{{ orderDetail.orderSn }}</div>
        </div>
      </div>
      <div class="title">核销说明</div>
      <div class="content">
        <div
          v-if="orderDetail.exchangeDesc"
          v-html="orderDetail.exchangeDesc"
        ></div>
        <div class="text-empty" v-else>暂无说明</div>
      </div>
      <!-- <div class="title">核销说明</div> -->
    </div>
    <div class="footer" @click="showDialog" v-if="orderDetail.status == 1">
      核销
    </div>
    <!-- <div class="success">
      <div class="success-t">
        <img
          v-if="orderDetail.status == 1"
          src="@/assets/img/waitHandle.png"
          alt=""
        />
        <img v-else src="@/assets/img/ivon_success.png" alt="" />
      </div>
      <div class="success-b">{{ returnStatus(orderDetail.status) }}</div>
    </div>
    <div class="goods">
      <div class="goods-l">
        <img :src="orderDetail.skuImageUrl" alt="" />
      </div>
      <div class="goods-r">{{ orderDetail.changeTitle }}</div>
    </div>
    <div class="orderInfo">
      <div class="item">
        <div class="item-l">支付积分：</div>
        <div class="item-r">{{ orderDetail.pointsPrice }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换数量：</div>
        <div class="item-r">{{ orderDetail.exchangeGoodsNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换时间：</div>
        <div class="item-r">{{ orderDetail.insertTime }}</div>
      </div>
      <div class="item te">
        <div class="item-l">订单号：</div>
        <div class="item-r">{{ orderDetail.orderSn }}</div>
      </div>
      <div class="itemTe">
        <div class="item-l">核销说明：</div>
        <div class="item-r" v-html="orderDetail.exchangeDesc"></div>
      </div>
    </div>
    <div class="more" @click="showDialog" v-if="orderDetail.status == 1">
      核销
    </div> -->
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">确定要核销兑换商品吗？</div>
        <div class="tips-b">
          <div class="tips-bl">
            <img src="@/assets/img/notice.png" alt="" />
          </div>
          <div class="tips-br">请仔细确认哦</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getOrderDetailUrl, handleOrderUrl } from "./api.js";
import { statusOps } from "./map.js";
import { getOption } from "@/utils/utils.js";

var moment = require("moment");
export default {
  name: "verificationDetail",
  data() {
    return {
      id: "",
      isDialog: false,
      orderDetail: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getActivityDetail();
  },
  methods: {
    showDialog() {
      this.isDialog = true;
    },
    confirm() {
      this.isDialog = false;
      this.handle();
    },
    returnStatus(status) {
      if (status) {
        return getOption(status, statusOps, "key")["value"];
      } else {
        return "待核销";
      }
    },
    handle() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .post(`${handleOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.$toast({ message: "核销成功", duration: 2000 });
            this.getActivityDetail();
          } else {
          }
        });
    },
    getActivityDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .post(`${getOrderDetailUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.orderDetail = res.data;
            if (this.orderDetail.skuImageUrl) {
              this.orderDetail.skuImageUrl = this.$handleImg(
                375,
                375,
                this.orderDetail.skuImageUrl
              );
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.verificationDetail {
  box-sizing: border-box;
  min-height: 100%;
  padding: 32px;
  background: #f5f5f5;
  padding-bottom: calc(
    128px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    128px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .show-card_wrap {
    margin-bottom: 32px;
    background: #ffffff;
    border-radius: 16px;
    padding: 0 32px 32px 32px;
    .order-info {
      width: 100%;
      display: flex;
      line-height: 96px;
      font-weight: 400;
      .time {
        flex: 1;
        font-size: 28px;
        color: #646668;
      }
      .status {
        font-size: 32px;
        color: #fe6f16;
      }
    }
    .order-detail {
      margin-top: 32px;
      display: flex;
      .left-pic {
        min-width: 128px;
        margin-right: 16px;
        img {
          width: 128px;
          height: 128px;
          vertical-align: middle;
        }
      }
      .right-content {
        max-width: calc(100% - 144px);
        flex: 1;
        .content-title {
          font-size: 32px;
          font-weight: bold;
          color: #323334;
          line-height: 44px;
          margin-bottom: 28px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .content-integral {
          line-height: 36px;
          font-weight: 400;
          color: #646668;
          font-size: 24px;
          span {
            margin-right: 8px;
            font-weight: bold;
            color: #fe6f16;
            font-size: 40px;
            line-height: 60px;
          }
        }
      }
    }
  }
  .main {
    background: #ffffff;
    border-radius: 16px;
    .title {
      font-size: 32px;
      font-weight: bold;
      color: #323334;
      line-height: 44px;
      padding: 26px 0 26px 32px;
    }
    .item {
      display: flex;
      box-sizing: border-box;
      padding: 0 32px;
      line-height: 96px;
      width: 100%;
      .item-l {
        width: 186px;
        min-width: 186px;
        font-size: 32px;
        font-weight: 400;
        color: #646668;
      }
      .item-r {
        flex: 1;
        font-weight: 600;
        color: #323334;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > div {
          line-height: 44px;
          white-space: normal;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
    > .content {
      width: 100%;
      box-sizing: border-box;
      // margin-bottom: 80px;
      font-size: 32px;
      line-height: 1.5;
      color: #333333;
      word-wrap: break-word;
      white-space: normal;
      word-wrap: break-word;
      word-break: break-all;
      padding: 0 32px 32px;
      > .text-empty {
        height: 96px;
        line-height: 96px;
        background: #eeeeee;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: #666666;
      }
      ::v-deep img {
        width: 100%;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: inset 0px 2px 0px 0px rgba(230, 230, 230, 1);
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    line-height: 96px;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #409eff;
    letter-spacing: 8px;
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 28px;
      }
    }
  }
}
</style>
